import { gql, TypedDocumentNode } from '@apollo/client';

export const FETCH_HEADQUARTERS: TypedDocumentNode = gql`
    query {
        getNetworks(filter: { type: "headquarter" }) {
            network_id
            type
            region
            subtitle
            tollFrees: toll_frees
            address
            telephoneNumbers: telephone_numbers
            faxNumbers: fax_numbers
            emails
            websites
            additional_info
            status
        }
    }
`;

export const FETCH_CONTINENTS: TypedDocumentNode = gql`
    query {
        getNetworks(filter: { type: "continent" }) {
            network_id
            type
            region
            subtitle
            tollFrees: toll_frees
            companyName: company_name
            address
            telephoneNumbers: telephone_numbers
            faxNumbers: fax_numbers
            emails
            websites
            additional_info
            status
        }
    }
`;

export const FETCH_GLOBAL_NETWORK_SETTINGS = gql`
    query getGlobalNetworkSettings {
        getGlobalNetworkSettings {
            pageTitle: page_title
            continentTitle: continent_title
            pageDescription: page_description
            pageImage: page_image
        }
    }
`;
