import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
    FETCH_CONTINENTS,
    FETCH_HEADQUARTERS,
    FETCH_GLOBAL_NETWORK_SETTINGS,
} from 'graphql/cms/global-network/fetch-globalnetwork';
import { MainColumn } from 'ui/component/main-column';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface Network {
    networkId: string;
    region: string;
    subtitle: string;
    companyName: string;
    address: string;
    telephoneNumbers: string;
    faxNumbers?: string;
    emails: string;
    websites: string;
    tollFrees?: string;
    status: boolean;
}

interface GlobalNetworkSettings {
    pageTitle: string;
    pageImage: string;
    pageDescription: string;
    continentTitle: string;
}

const sanitizeHtml = (html: string) => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(html, 'text/html');
    return parsedDocument.body.innerHTML;
};
const formatWithLabel = (value: string, fieldName: string): string[] => {
    const entries = value.split(',').map(item => item.trim());
    if (entries.length === 1) {
        return [`${fieldName}: ${entries[0]}`];
    }
    return entries.map((entry, index) => `${fieldName} ${index + 1}: ${entry}`);
};

export const Content: React.FC = () => {
    const t = usePhraseTranslater();
    const {
        loading: loadingHeadquarters,
        error: errorHeadquarters,
        data: dataHeadquarters,
    } = useQuery<{ getNetworks: Network[] }>(FETCH_HEADQUARTERS, {
        notifyOnNetworkStatusChange: true,
    });

    const {
        loading: loadingContinents,
        error: errorContinents,
        data: dataContinents,
    } = useQuery<{ getNetworks: Network[] }>(FETCH_CONTINENTS, {
        notifyOnNetworkStatusChange: true,
    });

    const {
        loading: loadingSettings,
        error: errorSettings,
        data: dataSettings,
    } = useQuery<{ getGlobalNetworkSettings: GlobalNetworkSettings }>(
        FETCH_GLOBAL_NETWORK_SETTINGS,
    );

    const [activeRegion, setActiveRegion] = useState<string | null>(null);

    const toggleRegion = (regionName: string) => {
        setActiveRegion(activeRegion === regionName ? null : regionName);
    };

    if (loadingHeadquarters || loadingContinents || loadingSettings) return <p>{t('Loading...')}</p>;
    if (errorHeadquarters || errorContinents || errorSettings) {
        return (
            <p>
                Error:
                {errorHeadquarters?.message ||
                    errorContinents?.message ||
                    errorSettings?.message}
            </p>
        );
    }

    const uniqueRegions = Array.from(
        new Set<string>(
            dataContinents!.getNetworks
                .filter(network => network.status)
                .map(network => network.region),
        ),
    );
    const safePageDescription = sanitizeHtml(
        dataSettings?.getGlobalNetworkSettings?.pageDescription || '',
    );

    return (
        <MainColumn className="maincolumn-homepage">
            <h2 className={styles.networkTitle}>
                {dataSettings?.getGlobalNetworkSettings?.pageTitle || 'Global Networks'}
            </h2>

            <div className={styles.imageContainer}>
                <img
                    src={`/media/chefworks/global_network/${dataSettings?.getGlobalNetworkSettings?.pageImage}`}
                    alt="Global Networks Map"
                    className={styles.networkImage}
                />
            </div>

            <div className={styles.introText}>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: safePageDescription }} />
            </div>

            <div className={styles.headquartersContainer}>
                {dataHeadquarters &&
                    dataHeadquarters.getNetworks
                        .filter(network => network.status)
                        .map(network => (
                            <div key={network.networkId} className={styles.headquarterBlock}>
                                <p className={styles.headquarterheading}>{network.region}</p>
                                <span className={styles.headquarterheadingspan}>{network.subtitle}</span>
                                <p>{network.address}</p>
                                {network.telephoneNumbers && (
                                    <div>
                                        {formatWithLabel(
                                            network.telephoneNumbers,
                                            'Telephone',
                                        ).map((telephone) => {
                                            const [label, telephoneValue] = telephone.split(': ');

                                            return (
                                                <p key={telephoneValue}>
                                                    {label}
                                                    :
                                                    <span className={styles.paddingLeft}>
                                                        {telephoneValue}
                                                    </span>
                                                </p>
                                        );
                                        })}
                                    </div>
                                )}
                                {network.tollFrees && (
                                    <div>
                                        {formatWithLabel(network.tollFrees, 'Toll-Free').map(
                                            (tollFree) => {
                                                const [label, tollFreeValue] = tollFree.split(': ');

                                                return (
                                                    <p key={tollFreeValue}>
                                                        {label}
                                                        :
                                                        <span className={styles.paddingLeft}>
                                                            {' '}
                                                            {tollFreeValue}
                                                        </span>
                                                    </p>
                                            );
                                            },
                                        )}
                                    </div>
                                )}
                                {network.faxNumbers && (
                                    <div>
                                        {formatWithLabel(network.faxNumbers, 'Fax').map((fax) => {
                                            const [label, faxValue] = fax.split(': ');

                                            return (
                                                <p key={faxValue}>
                                                    {label}
                                                    :
                                                    <span className={styles.paddingLeft}>{faxValue}</span>
                                                </p>
                                            );
                                        })}
                                    </div>
                                )}
                                {network.emails && (
                                    <div className={styles.emailContainer}>
                                        {formatWithLabel(network.emails, 'Email').map((email) => {
                                            const [label, emailValue] = email.split(': ');

                                            return (
                                                <p key={emailValue}>
                                                    {label}
                                                    :
                                                    <a
                                                        href={`mailto:${emailValue}`}
                                                        className={styles.valuered}
                                                    >
                                                        {emailValue}
                                                    </a>
                                                </p>
                                            );
                                        })}
                                    </div>
                                )}
                                {network.websites && (
                                    <div className={styles.websiteContainer}>
                                        {formatWithLabel(network.websites, 'Website').map(
                                            (website) => {
                                                const [label, websiteValue] = website.split(': ');

                                                return (
                                                    <p key={websiteValue}>
                                                        {label}
                                                        :
                                                        <a
                                                            href={websiteValue}
                                                            className={styles.valuered}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {websiteValue}
                                                        </a>
                                                    </p>
                                                );
                                            },
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
            </div>
            <h2 className={styles.contientnetworkTitle}>
                {dataSettings?.getGlobalNetworkSettings?.continentTitle || t('Please click on the appropriate continent below to find your local contact information.')}
            </h2>
            {uniqueRegions.map(region => (
                <div key={region} className={styles.continentSection}>
                    <div
                        className={styles.regionHeader}
                        role="button"
                        tabIndex={0}
                        onClick={() => toggleRegion(region)}
                        onKeyPress={() => toggleRegion(region)}
                    >
                        <h4>{region}</h4>
                    </div>
                    {activeRegion === region && (
                        <div className={styles.regionContent}>
                            {dataContinents?.getNetworks
                                .filter(
                                    network => network.region === region && network.status,
                                )
                                .map(network => (
                                    <div
                                        key={network.networkId}
                                        className={styles.continentBlock}
                                    >
                                        <h4>{network.subtitle}</h4>
                                        <p>{network.companyName}</p>
                                        <p>{network.address}</p>

                                        {/* Telephone Numbers */}
                                        {network.telephoneNumbers && (
                                            <div>
                                                {formatWithLabel(
                                                    network.telephoneNumbers,
                                                    'Telephone',
                                                ).map(telephone => (
                                                    <p key={telephone}>{telephone}</p>
                                                ))}
                                            </div>
                                        )}
                                        {network.tollFrees && (
                                            <div>
                                                {formatWithLabel(network.tollFrees, 'Toll-Free').map(
                                                    tollFree => (
                                                        <p key={tollFree}>{tollFree}</p>
                                                    ),
                                                )}
                                            </div>
                                        )}
                                        {network.faxNumbers && (
                                            <div>
                                                {formatWithLabel(network.faxNumbers, 'Fax').map(fax => (
                                                    <p key={fax}>{fax}</p>
                                                ))}
                                            </div>
                                        )}
                                        {network.emails && (
                                            <div className={styles.emailContainer}>
                                                {formatWithLabel(network.emails, 'Email').map((email) => {
                                                    const [label, emailValue] = email.split(': ');

                                                    return (
                                                        <p key={emailValue}>
                                                            {label}
                                                            :
                                                            <a
                                                                href={`mailto:${emailValue}`}
                                                                className={styles.valuered}
                                                            >
                                                                {emailValue}
                                                            </a>
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        {network.websites && (
                                            <div className={styles.websiteContainer}>
                                                {formatWithLabel(network.websites, 'Website').map(
                                                    (website) => {
                                                        const [label, websiteValue] = website.split(': ');

                                                        return (
                                                            <p key={websiteValue}>
                                                                {label}
                                                                :
                                                                <a
                                                                    href={websiteValue}
                                                                    className={styles.valuered}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {websiteValue}
                                                                </a>
                                                            </p>
                                                        );
                                                    },
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            ))}
        </MainColumn>
    );
};
