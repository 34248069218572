import React from 'react';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { HeadTag } from '@silkpwa/module/react-component/head-tag';
import { injectProps } from '@silkpwa/redux';
import { connectConfig } from '@silkpwa/module/react-component/connect-config';
import { OrganizationRichSnippet } from 'ui/component/rich-snippets/organization-rich-snippet';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

interface Props {
    ecommerceConfig: SilkRestappDataConfigInfoInterface;
    window: Window;
}
const Head: React.FC<Props> = ({ ecommerceConfig, window }) => (
    <React.Fragment>
        <DocumentTitle exact>Hospitality Industry Global Distribution | Chef Works</DocumentTitle>
        <HeadTag
            tag="link"
            rel="canonical"
            href={window.location.href.replace(/\?.*$/, '')}
        />
        <HeadTag
            tag="meta"
            name="description"
            content={ecommerceConfig.extension_attributes?.cw_homepage_description}
        />
        <OrganizationRichSnippet />
    </React.Fragment>
);

const ConnectedHead = connectConfig(injectProps('window')(Head));

export { ConnectedHead as Head };
